import { useContext, useEffect, useState } from "react";
import { WebSocketContext } from "../../service/WebsocketProvider";
import './Amendments.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { AmendmentContent, AmendmentPayload } from "./AmendmentModel";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";

function AmendmentsComponent(props:any) {
    const {joinGroup, onMessage, leaveGroup} = useContext(WebSocketContext);

    const [amendment, setAmendment] = useState<AmendmentContent | undefined>(undefined);
    const [status, setStatus] = useState<String | undefined>(undefined);

    const queryParams = new URLSearchParams(useLocation().search);

    const simulationId = props.simulationIdConfig ?? queryParams.get("simulationId");

    function handleMessage(data: AmendmentPayload) {
        if(data.status){
            setStatus(data.status);
        }
        else if(data.content) {
            setAmendment(data.content);
        }
    }
    
    useEffect(() => {
        joinGroup(`Amendments-${simulationId}`);
        onMessage([`Amendments-${simulationId}`], handleMessage);

        return () => {
            leaveGroup(`Amendments-${simulationId}`);
        };
    }, [simulationId]);

    return (
        <div className="amendments-frame">
            <div className="amendments-header">
                <motion.div
                    animate={{ opacity: status ? 1 : 0 }}
                    initial={{ opacity: 0 }}
                    transition={{ duration: 0.3 }}
                >
                    <div className="amendments-header-step">
                        <div className="marker-line"></div>
                        <h3>{status}</h3>
                    </div>
                </motion.div>
                <img src="logo.png" alt="Project logo" />
            </div>
            <div className="amendments-article">
                <motion.div
                    animate={{ opacity: amendment ? 1 : 0 }}
                    initial={{ opacity: 0 }}
                    transition={{ duration: 0.3 }}
                >
                    <h1>{amendment?.articleId}</h1>
                    <h3><i>{amendment?.articleSubtitle}</i></h3>
                </motion.div>
            </div>
            <div className="amendments-group">
                <motion.div
                    animate={{ opacity: amendment ? 1 : 0 }}
                    initial={{ opacity: 0 }}
                    transition={{ duration: 0.3 }}
                >
                    <h3><FontAwesomeIcon className="spacer" icon={icon({name: 'book-section', style: 'regular'})} /> Amendment {amendment?.id}(V{amendment?.version})</h3>
                    <h3><FontAwesomeIcon className="spacer" icon={icon({name: 'briefcase', style: 'regular'})} /> {amendment?.subgroup}</h3>
                    <h4><FontAwesomeIcon className="spacer" icon={icon({name: 'people-group', style: 'regular'})} /> {amendment?.group}</h4>
                </motion.div>
            </div>
            <div className="amendments-image">
                <motion.div
                    animate={{ opacity: amendment ? 1 : 0 }}
                    initial={{ opacity: 0 }}
                    transition={{ duration: 0.3 }}
                >
                    <div className="amendments-image-clip">
                    <img src={`amendment-images/art-${amendment?.articleId}.jpeg`} />
                        <svg width="0" height="0" viewBox="0 0 1 1">
                            <defs>
                                <clipPath id="clip-path" clipPathUnits="objectBoundingBox">
                                <path d="M0.201,0.203c-0.069,0-0.136,0.009-0.199,0.027v0.427c0.059-0.034,0.128-0.053,0.199-0.053c0.219,0,0.397,0.176,0.399,0.393h0.394C0.993,0.427,0.645,0.203,0.201,0.203z"/>
                                    <path d="M0.201,0.689c-0.076,0-0.146,0.027-0.2,0.072v0.239h0.514C0.513,0.828,0.373,0.689,0.201,0.689z"/>
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                </motion.div>
            </div>
        </div>
    );
}

export default AmendmentsComponent;