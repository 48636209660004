import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import './Session.css';
import { motion } from "framer-motion";
import { useContext, useState } from "react";
import { WebSocketContext } from "../../service/WebsocketProvider";
import SessionPayload from "./SpeakerModel";
import SeatsHemicylceComponent from "../seats/SeatsHemicycle";
import { useLocation } from "react-router-dom";
import { Howl } from "howler";

function InternalSessionSpeakerComponent() {
    const {joinGroup, onMessage} = useContext(WebSocketContext);
    const [payload, setPayload] = useState<SessionPayload>();
    const [dismiss, setDismiss] = useState<Boolean>(true);

    const queryParams = new URLSearchParams(useLocation().search);
    const muted = queryParams.get("muted") === "true" ? true : false;
      
    const playAudio = (file: string) => {
      const audio = new Howl({src: file +".mp3", volume: 1});
      audio.play();
    };

    joinGroup("Speaker");

    function handleMessage(data: any) {
        if(data.dismiss) {
          setDismiss(true);
          setTimeout(() => {
            setPayload(data);
          }, 300);
        }
        else {
          setPayload(data);
          setDismiss(false);
        }

        if(data.alert){
          if(data.alert.sound && data.alert.sound != "None" && !muted){
              playAudio(data.alert.sound);
          }
        }
    }
    
    onMessage(["Speaker"], handleMessage);

    return (
      <div className="session-frame">
        <div className="session-header">
            <img src="host.svg" alt="host logo" style={{height: "20vh"}} />
            <h4>Plenary Session</h4>
        </div>
        <div className="session-content">
            <motion.div
            animate={{ opacity: !dismiss && payload?.speaker != null ? 1 : 0, height: !dismiss && payload?.speaker != null ? 'auto': 0 }}
            initial={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
            >
              <div className="session-content-internal">
                <div style={{display: 'block', width: '55vw'}}>
                  <SeatsHemicylceComponent activeSeat={payload?.speaker?.seat} />
                </div>
                <div className="session-box">
                    <p>Now speaking:</p>
                    <br />
                    <h4>{payload?.speaker?.name + " " + payload?.speaker?.lastname}</h4>
                    <h6><FontAwesomeIcon icon={icon({name: 'users', style: 'light'})} style={{marginRight: "1vmin"}}/> {payload?.speaker?.display}</h6>
                    <h6><FontAwesomeIcon icon={icon({name: 'loveseat', style: 'light'})} style={{marginRight: "1vmin"}}/> {payload?.speaker?.seat}</h6>
                    
                    <br />
                </div>
              </div>
            </motion.div>
            <motion.div
              animate={{ opacity: !dismiss && payload?.alert != null ? 1 : 0, height: !dismiss && payload?.alert != null ? 'auto' : 0 }}
              initial={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
              >
              <div>
                  <h4>{payload?.alert?.icon && (<FontAwesomeIcon icon={icon({name: 'bell-on', style: 'regular'})} style={{marginRight: "2vmin"}} bounce/>)}{payload?.alert?.title}</h4>
                  <h6>{payload?.alert?.subtitle}</h6>
              </div>
            </motion.div>
        </div>
        <div className="session-footer">
            <img src="project-footer.png" alt="project footer image" />
        </div>
      </div>
    );
  }

export default InternalSessionSpeakerComponent;