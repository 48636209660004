import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import './Session.css';
import { motion } from "framer-motion";
import { useContext, useEffect, useRef, useState } from "react";
import { WebSocketContext } from "../../service/WebsocketProvider";
import SessionPayload, { SpeechTimerStages } from "./SpeakerModel";
import { useLocation } from "react-router-dom";
import { Howl } from "howler";

function SessionSpeakerComponent(props:any) {
    const {joinGroup, onMessage, leaveGroup} = useContext(WebSocketContext);
    const [payload, setPayload] = useState<SessionPayload>();
    const [dismiss, setDismiss] = useState<Boolean>(true);
    const [speechTimer, setSpeechTimer] = useState<string | null>(null);
    //const [countdown, setCountdown] = useState<number>(0);
    let countdown = 0;

    const queryParams = new URLSearchParams(useLocation().search);
    const muted = queryParams.get("muted") === "true" ? true : false;
    const occasionId = props.occasionIdConfig ?? queryParams.get("occasionId");

    const countdownRef = useRef<NodeJS.Timeout | null>(null);
      
    const playAudio = (file: string) => {
      const audio = new Howl({src: "announcement/"+ file +".mp3", volume: 1});
      audio.play();
    };

    const startTimer = () => {
      if (countdownRef.current) return; // Prevent multiple timers

      countdownRef.current = setInterval(() => {
          countdown -= 1;
          setSpeechTimer(formatTimer(countdown));
      }, 1000);
    };
  
    const stopTimer = () => {
      if (countdownRef.current) {
        setSpeechTimer(null);
        countdown = 0;
        clearInterval(countdownRef.current);
        countdownRef.current = null;
      }
    };

    function handleMessage(data: any) {
        if(data.dismiss) {
          setDismiss(true);
          setTimeout(() => {
            setPayload(data);
          }, 300);
        }
        else {
          setPayload(data);
          setDismiss(false);
        }

        if(data.alert){
          if(data.alert.sound && data.alert.sound != "None" && !muted){
              playAudio(data.alert.sound);
          }
        }

        if(data.timer) {
          switch (data.timer.stage) {
            case SpeechTimerStages.setTime:
              countdown = data.timer.countSeconds!;
              setSpeechTimer(formatTimer(countdown));
            break;
            case SpeechTimerStages.start:
              startTimer();
            break;
            case SpeechTimerStages.invalidate:
              stopTimer();
            break;
          }
        }
    }

    const formatTimer = (timer: number): string => {
      const minutes = Math.floor(Math.abs(timer) / 60);
      const seconds = Math.abs(timer) % 60;
      const sign = timer < 0 ? '-' : '';
    
      return `${sign}${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };
    
    useEffect(() => {
      joinGroup(`Speaker-${occasionId}`);
      onMessage([`Speaker-${occasionId}`], handleMessage);


      return () => {
        stopTimer();
        leaveGroup(`Speaker-${occasionId}`);
      }; // Cleanup on unmount
    }, [occasionId]);

    return (
      <div className="session-frame">
        <div className="session-header">
            <img src="host.svg" alt="host logo" style={{height: "20vh"}} />

            {speechTimer ? <h2>{speechTimer}</h2> : <h4>#PEL2024</h4>}
            
        </div>
        <div className="session-content">
            <motion.div
            animate={{ opacity: !dismiss && payload?.speaker != null ? 1 : 0, height: !dismiss && payload?.speaker != null ? 'auto': 0 }}
            initial={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
            >
            <div className="session-box">
                <p>Now speaking:</p>
                <br />
                <h4>{payload?.speaker?.name + " " + payload?.speaker?.lastname}</h4>
                <h6><FontAwesomeIcon icon={icon({name: 'users', style: 'light'})} style={{marginRight: "1vmin"}}/> {payload?.speaker?.display}</h6>
                <br />
            </div>
            </motion.div>
            <motion.div
              animate={{ opacity: !dismiss && payload?.alert != null ? 1 : 0, height: !dismiss && payload?.alert != null ? 'auto' : 0 }}
              initial={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
              >
              <div>
                  <h4>{payload?.alert?.icon && (<FontAwesomeIcon icon={icon({name: 'bell-on', style: 'regular'})} style={{marginRight: "2vmin"}} bounce/>)}{payload?.alert?.title}</h4>
                  <h6>{payload?.alert?.subtitle}</h6>
              </div>
            </motion.div>
        </div>
        <motion.div
            animate={{ opacity: payload?.speaker == null ? 1 : 0}}
            initial={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            >
          <div className="session-footer">
              <img src="project-footer.png" alt="project footer image" />
          </div>
        </motion.div>
      </div>
    );
  }

export default SessionSpeakerComponent;