import React, { useEffect, useState } from 'react';
import './Loader.css';

function LoadComponent(props:any) {
    const { modeIndex } = props;
    const [dateTime, setDateTime] = useState(new Date());
  
    useEffect(() => {
      const intervalId = setInterval(() => {
        setDateTime(new Date());
      }, 1000);
  
      return () => clearInterval(intervalId);
    }, []);

    const colorModes = [
      { color: 'gray', name: 'disconnected' },
      { color: 'green', name: 'connected' },
      { color: 'orange', name: 'connecting' },
      { color: 'red', name: 'error' },
    ];
    
    return (
      <div className="App">
          <img src="logo.png" className="App-logo" alt="logo" />
          <div>
            <h4 className="App-status"><span className="App-status-dot" style={{backgroundColor: colorModes[modeIndex].color}}></span> LPE Projection</h4>
            <p>{dateTime.toLocaleString('en-EN', { timeZone: 'Europe/Paris', day: '2-digit', month: 'long', year: 'numeric', hourCycle: 'h24', hour: '2-digit', minute: '2-digit', second: '2-digit' }).replace('at', '')}</p>
          </div>
      </div>
    );
}

export default LoadComponent;
