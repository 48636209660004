import { useContext, useEffect, useRef, useState } from "react";
import './Audiovisual.css';
import { WebSocketContext } from "../../service/WebsocketProvider";
import { motion } from "framer-motion";
import {Howl} from "howler";
import ProjectionControls from "./AudiovisualModel";
import { useLocation } from "react-router-dom";

function AudiovisualComponent(props:any) {
  const { joinGroup, onMessage, leaveGroup } = useContext(WebSocketContext);
  const [dismissBox, setDismissBox] = useState<Boolean>(true);
  const [boxContent, setBoxContent] = useState<string | undefined | null>(undefined);

  const queryParams = new URLSearchParams(useLocation().search);
  const muted = queryParams.get("muted") === "true" ? true : false;

  const occasionId = props.occasionIdConfig ?? queryParams.get("occasionId");

  const [statusDot, setStatusDot] = useState('orange');

  const [videoOpacity, setVideoOpacity] = useState<number[]>([0, 0]);
  const [showLogo, setshowLogo] = useState<boolean>(true);
  var isPlaying = false;

  const audioUrls = ["music/ambient1.mp3", "music/aspirate.mp3", "music/crossing.mp3", "music/elements.mp3", "music/good-day.mp3", "music/legends.mp3", "music/movement.mp3"];
  const videoUrls = ["video/circle.mp4", "video/square-rotate.mp4"];

  let breakCurrentEvents = false
  var audioOne = useRef<Howl | null>(null);
  var audioTwo = useRef<Howl | null>(null);
  var logoAudio = useRef<Howl | null>(null);

  var videoBlobs = useRef<string[]>([]);

  const videoOne = useRef<HTMLVideoElement>(null);
  const videoTwo = useRef<HTMLVideoElement>(null);

  function endEventAudioOne() {
    audioOne.current!.unload();
    audioOne.current = new Howl({src: [audioUrls[Math.floor(Math.random() * audioUrls.length)]], volume: 0, onend: endEventAudioOne, onplay: playEventAudioOne});
  }

  function endEventAudioTwo() {
    audioTwo.current!.unload();
    audioTwo.current = new Howl({src: [audioUrls[Math.floor(Math.random() * audioUrls.length)]], volume: 0, onend: endEventAudioTwo, onplay: playEventAudioTwo});
  }

  function playEventAudioOne() {
    const remainingDuration = (audioOne.current!.duration() - audioOne.current!.seek() - 2) * 1000;

      setTimeout(() => {
        console.log("Now fading 0", remainingDuration);
        console.log(isPlaying);
        if (isPlaying) {
          audioOne.current!.fade(0.5, 0, 2);

          audioTwo.current!.play();
          audioTwo.current!.fade(0, 0.5, 2);
        }
      }, remainingDuration);
  }

  function playEventAudioTwo() {
    const remainingDuration = (audioTwo.current!.duration() - audioTwo.current!.seek() - 2) * 1000;

      setTimeout(() => {
        console.log("Now fading");
        if (isPlaying) {
          audioTwo.current!.fade(0.5, 0, 2);

          audioOne.current!.play();
          audioOne.current!.fade(0, 0.5, 2);
        }
      }, remainingDuration);
  }

  const initAudio = () => {
    logoAudio.current = new Howl({src: ['music/logo-jingle.mp3'], volume: 1});
    audioOne.current = new Howl({src: [audioUrls[Math.floor(Math.random() * audioUrls.length)]], volume: 0, onend: endEventAudioOne, onplay: playEventAudioOne});
    audioTwo.current = new Howl({src: [audioUrls[Math.floor(Math.random() * audioUrls.length)]], volume: 0, onend: endEventAudioTwo, onplay: playEventAudioTwo});
  } 

  const initVideoEventListener = () => {
    const video: (HTMLVideoElement)[] = [document.querySelector('#video-channel-0')!, document.querySelector('#video-channel-1')!];
    
    for (let i = 0; i < videoUrls.length; i++) {
      fetch(videoUrls[i])
        .then(response => response.blob())
        .then(blob => {
          videoBlobs.current.push(URL.createObjectURL(blob));
        })
        .catch(error => {
          console.error('Error fetching video:', error);
        });
    }
    
    const channelLaunched = [false, false];

    videoOne.current!.addEventListener("timeupdate", () => {
      if (!channelLaunched[0] && videoOne.current!.duration - videoOne.current!.currentTime <= 2 && !breakCurrentEvents) {
        channelLaunched[0] = true;

        videoTwo.current!.play();
        setVideoOpacity([0, 1]);
      }
    });

    videoTwo.current!.addEventListener("timeupdate", () => {
      if (!channelLaunched[1] && videoTwo.current!.duration - videoTwo.current!.currentTime <= 2 && !breakCurrentEvents) {
        channelLaunched[1] = true;
        videoOne.current!.play();
        setVideoOpacity([1, 0]);
      }
    });


    videoOne.current!.addEventListener("ended", () => {
      channelLaunched[0] = false;

      videoOne.current!.src = videoBlobs.current[Math.floor(Math.random() * video.length)];
    });

    videoTwo.current!.addEventListener("ended", () => {
      channelLaunched[1] = false;

      videoTwo.current!.src = videoBlobs.current[Math.floor(Math.random() * video.length)];
    });
  }

  const playAudio = () => {

    if(!audioOne.current!.playing() && !audioOne.current!.playing() && !logoAudio.current!.playing() && !muted){
      console.log("Playing");
      isPlaying = true;
      console.log(isPlaying);
      audioOne.current!.play();
      audioOne.current!.fade(0, 0.5, 2000);
    }
  };

  const playVideo = () => {
    if(videoOne.current!.currentTime == 0 && videoTwo.current!.currentTime == 0){
      videoOne.current!.play();
      setshowLogo(false);
      setVideoOpacity([1, 0]);
    }
  }

  const fadeToLogo = () => {
    breakCurrentEvents = true;

    isPlaying =false;
    audioOne.current!.fade(0.5, 0, 2000);
    audioTwo.current!.fade(0.5, 0, 2000);

    setTimeout(() => {
      audioOne.current!.unload();
      audioTwo.current!.unload();
      initAudio();
      logoAudio.current!.play();
      setTimeout(() => {
        setshowLogo(true);
        breakCurrentEvents = false;
      }, 1000);
    }, 2000);

    setVideoOpacity([0, 0]);
    videoOne.current!.pause();
    videoOne.current!.currentTime = 0;
    videoTwo.current!.pause();
    videoTwo.current!.currentTime = 0

    setDismissBox(true);
    const timeoutId = setTimeout(() => {
      setBoxContent(null);
      clearTimeout(timeoutId);
    }, 2000);
  };

  //Websocket
  function handleMessage(data: ProjectionControls) {
    console.log("Message received");
    if (data.playAudio) {
      playAudio();
    }
    if (data.showVideo) {
      playVideo();
    }
    if (data.showLogo) {
      fadeToLogo();
    }
    if(!data.stepData && data.showStep == false){
      setDismissBox(true);
      const timeoutId = setTimeout(() => {
        setBoxContent(null);
        clearTimeout(timeoutId);
      }, 2000);
    }


    if (data.showStep && !muted) {
      setBoxContent(data.stepData);
      setDismissBox(false);
    }
  };

  useEffect(() => {
    console.log("Init workspace");
    initVideoEventListener();
    initAudio();

  }, [audioOne, audioTwo, videoOne, videoTwo, occasionId]);

  useEffect(() => {
    joinGroup(`Audiovisual-${occasionId}`);
    onMessage([`Audiovisual-${occasionId}`], handleMessage);


    return () => {
      leaveGroup(`Audiovisual-${occasionId}`);
    }; // Cleanup on unmount
  }, [occasionId]);

  // Show Time    
  const [dateTime, setDateTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDateTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="background-mixer">
      <div onClick={ () => {setStatusDot('transparent')}} className="audioVisual-status-dot" style={{backgroundColor: statusDot}}></div>
      <motion.div
        animate={{ opacity: videoOpacity[0] }}
        initial={{ opacity: 0 }}
        transition={{ duration: 0.7 }}
      >
        <video ref={videoOne} id="video-channel-0" className="video-frame" muted={true} playsInline>
          <source src="video/circle.mp4" type="video/mp4" />
        </video>
      </motion.div>
      <motion.div
        animate={{ opacity: videoOpacity[1] }}
        initial={{ opacity: 0 }}
        transition={{ duration: 0.7 }}
      >
        <video ref={videoTwo} id="video-channel-1" className="video-frame" muted={true} playsInline>
          <source src="video/circle.mp4" type="video/mp4" />
        </video>
      </motion.div>
      <div className="host-section">
        <motion.div
          animate={{ opacity: showLogo ? 1 : 0, height: showLogo ? "30vh" : "0vh" }}
          initial={{ opacity: 1, height: "30vh" }}
          transition={{ duration: 2 }}
        >
          <img src="host.svg" alt="host logo" style={{ height: "100%" }} />
        </motion.div>
      </div>
      <motion.div
        animate={{ opacity: !dismissBox && boxContent != null ? 1 : 0, height: !dismissBox && boxContent != null ? 'auto' : 0 }}
        initial={{ opacity: 0, height: 0 }}
        transition={{ duration: 2 }}
      >
        <div className="mixer-box">
          <h2>{boxContent}</h2>
          <p>{dateTime.toLocaleString('en-EN', { timeZone: 'Europe/Paris', day: '2-digit', month: 'long', year: 'numeric', hourCycle: 'h24', hour: '2-digit', minute: '2-digit', second: '2-digit' }).replace('at', '')}</p>
        </div>
      </motion.div>
    </div>
  );
}

export default AudiovisualComponent;