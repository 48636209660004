import React, { useState, useEffect, createContext, ReactNode } from 'react';

type SocketType = WebSocket | null;
type WebSocketContextType = {
    openSocket(): void;
    connectionStatus: number;
    joinGroup(group: string): void;
    leaveGroup(group: string): void;
    onMessage(channels: string[], callback: (data: any) => void): void;
}

export const WebSocketContext = createContext<WebSocketContextType>({
    openSocket: () => {},
    connectionStatus: 0,
    joinGroup: () => {},
    leaveGroup: () => {},
    onMessage: () => {},
});

interface WebSocketProviderProps {
  children: ReactNode;
}

function WebSocketProvider({ children }: WebSocketProviderProps) {
  const [webSocket, setwebSocket] = useState<SocketType>(null);
  const [connectionStatus, setConnectionStatus] = useState(0);
  const token = new URLSearchParams(window.location.search).get('access');
  

    function openSocket(){ 
        setConnectionStatus(2);
        const ws = new WebSocket('wss://lpedev.webpubsub.azure.com/client/hubs/Projection?access_token='+token, 'json.webpubsub.azure.v1');

        ws.onopen = () => {
            setConnectionStatus(2);
        };

        ws.onclose = (event) => {
        console.log(event);
        if(event.code <= 1002) {
            setConnectionStatus(0);
        }
        else {
            setConnectionStatus(3);
        }
        };

        ws.onerror = (event) => {
        console.log(event);
        setConnectionStatus(3);
        };

        setwebSocket(ws);

        return () => {
            ws.close();
        };
    }

    useEffect(() => {
        // Attach an event listener for the 'message' event, if the WebSocket object exists
        if (webSocket) {
        webSocket.onmessage = (event) => {
            const message = JSON.parse(event.data);
            
            if(message.type === "system" && message.event === "connected"){
            setConnectionStatus(1);
            }

        };
        }
    }, [webSocket]);

    function joinGroup(group: String) {
        console.log("joining Group:" + group);
        const message = JSON.stringify({
          "type": "joinGroup",
          "group": group
      });
      
        webSocket?.send(message);
    }

    function leaveGroup(group: String) {
        console.log("leaving Group:" + group);
        const message = JSON.stringify({
          "type": "leaveGroup",
          "group": group
      });
        //TODO: also remove event listener to avoid multiple listeners, if group is joined again
        webSocket?.send(message);
    }

    function onMessage(channels: string[], callback: any) {
        if (!webSocket) return;
        console.log("Websocket listening for", channels);

        webSocket.addEventListener("message", function(event) {
            const message = JSON.parse(event.data);

            if (message.type === "message" && channels.includes(message.group)) {
                const timeDifference = new Date().getTime() - new Date(message.data.timeStamp).getTime();

                // Smaller than 5 seconds
                if(timeDifference <= 5000){
                    callback(message.data);
                }
                else {
                    console.log("Canceled message! Message was more than 5 seconds late");
                }
            }
        });
    }      
    
  return (
    <WebSocketContext.Provider value={{openSocket, connectionStatus, joinGroup, onMessage, leaveGroup}}>
      {children}
    </WebSocketContext.Provider>
  );
}

export { WebSocketProvider };
