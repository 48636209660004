interface Alert {
    icon: boolean;
    title: string;
    subtitle: string;
    sound?: string;
}

interface Speaker {
    name: string;
    lastname: string;
    seat: string;
    display: string;
    timeStamp: Date;
}

interface Timer {
    countSeconds?: number;
    stage: SpeechTimerStages;
    initiatedAt: Date;
}

export enum SpeechTimerStages {
    setTime = 1,
    start = 2,
    invalidate = 0,
}

interface SessionPayload {
    alert?: Alert;
    speaker?: Speaker;
    timer?: Timer;
    dismiss?: boolean;
    timeStamp: Date;
}

export default SessionPayload;
  