import { useContext, useEffect, useState } from "react";
import { WebSocketContext } from "../../service/WebsocketProvider";
import SessionSpeakerComponent from "../speaker/SessionSpeaker";
import './Stage.css';
import AudiovisualComponent from "../audiovisual/Audiovisual";
import AmendmentsComponent from "../amendments/Amendments";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import StageStates, { StageConf } from "./StageModel";
import LogoComponent from "../logo/Logo";

function StageComponent() {
    const {joinGroup, onMessage} = useContext(WebSocketContext);

    const queryParams = new URLSearchParams(useLocation().search);
    const screen = queryParams.get("screen");

    const [stageSettings, setStageSettings] = useState<StageConf>({occasionId: 0, simulationId: 0});
    const [screenSetting, setScreenSetting] = useState<StageStates>({logo: true, screen: ""});

    function handleMessage(data: StageStates) {
        console.log(data);
        if(data.screen === screen) {
            setScreenSetting(data);

            if(data.mix) {
                // Handle changing screens
            }
        }
    }

    function handleConf(data: StageConf) {
        console.log(data);
        setStageSettings(data);
        setScreenSetting({logo: true, screen: ""});
    }
    
    useEffect(() => {
        joinGroup(`Stage-Conf-${screen}`);
        onMessage([`Stage-Conf-${screen}`], handleConf);

        joinGroup(`Stage-${screen}`);
        onMessage([`Stage-${screen}`], handleMessage);
    });

    return (
        <div className="stage-frame">
            <motion.div
                animate={{ opacity: screenSetting.speaker ? 1 : 0 }}
                initial={{ opacity: 0 }}
                transition={{ duration: 1 }}
            >
                <SessionSpeakerComponent occasionIdConfig={stageSettings.occasionId} />
            </motion.div>
            <motion.div
                animate={{ opacity: screenSetting.audiovisual ? 1 : 0 }}
                initial={{ opacity: 0 }}
                transition={{ duration: 1 }}
            >
                <AudiovisualComponent occasionIdConfig={stageSettings.occasionId} />
            </motion.div>
            <motion.div
                animate={{ opacity: screenSetting.amendments ? 1 : 0 }}
                initial={{ opacity: 0 }}
                transition={{ duration: 1 }}
            >
                <AmendmentsComponent simulationIdConfig={stageSettings.simulationId} />
            </motion.div>
            <motion.div
                animate={{ opacity: screenSetting.logo ? 1 : 0 }}
                initial={{ opacity: 0 }}
                transition={{ duration: 1 }}
            >
                <LogoComponent />
            </motion.div>
        </div>
    );
}

export default StageComponent;