import LoadComponent from "./loader/Loader";
import './App.css';
import SessionSpeakerComponent from "./speaker/SessionSpeaker";
import { useContext } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { WebSocketContext } from "../service/WebsocketProvider";
import AudiovisualComponent from "./audiovisual/Audiovisual";
import InternalSessionSpeakerComponent from "./speaker/InternalSessionSpeaker";
import AmendmentsComponent from "./amendments/Amendments";
import StageComponent from "./stage/Stage";

function App() {
    const {openSocket, connectionStatus} = useContext(WebSocketContext);

    const handleFullscreen = () => {
      const element = document.documentElement;
      
      element.requestFullscreen();
    }

    const router = createBrowserRouter([
      {
        path: "/",
        element: <LoadComponent modeIndex={connectionStatus} />,
      },
      {
        path: "/speaker",
        element: <SessionSpeakerComponent />,
      },
      {
        path: "/internal-speaker",
        element: <InternalSessionSpeakerComponent />,
      },
      {
        path: "/audiovisual",
        element: <AudiovisualComponent />,
      },
      {
        path: "/amendments",
        element: <AmendmentsComponent />,
      },
      {
        path: "/stage",
        element: <StageComponent />,
      }
    ]);

    return (
      <div>
        {connectionStatus !== 1 ? (
          <div onClick={() => {handleFullscreen(); openSocket()}}>
            <LoadComponent modeIndex={connectionStatus} />
          </div>
        ) : (
          <RouterProvider router={router} />
        )}
      </div>
    );
  }

export default App;