import './Logo.css';

function LogoComponent() {

    return (
        <div className="logo-frame">
            <div className="logo-resize">
                <img src="host.svg" alt="host logo" style={{ height: "100%" }} />
            </div>
        </div>
    );
}

export default LogoComponent